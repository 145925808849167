import packageJson from '../../package.json';

export interface VersionResponse {
  currentVersion: string;
}

const CACHE_BUST_KEY = 'version_check_timestamp';

export async function getLatestVersion(): Promise<VersionResponse> {
  return {
    currentVersion: packageJson.version
  };
}

export function clearVersionCache(): void {
  localStorage.removeItem(CACHE_BUST_KEY);
}

export async function checkForUpdates(): Promise<boolean> {
  return false; // Always return false since we're only using local version
} 