import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { useAuthenticationStatus, useNhostClient } from '@nhost/react';
import debounce from 'lodash/debounce';

type Prompt = {
  id: string;
  title: string;
  content: string;
  user_id: string;
  created_at: string;
  pending?: boolean;
  dirty?: boolean;
};

type PromptsData = {
  prompts: Prompt[];
};

type InsertPromptData = {
  insert_prompts_one: Prompt;
};

type UpdatePromptData = {
  update_prompts_by_pk: Prompt;
};

type DeletePromptData = {
  delete_prompts_by_pk: Prompt;
};

const STORAGE_KEY = 'prompts_data';
const SYNC_QUEUE_KEY = 'prompts_sync_queue';
const ACTIVE_PROMPT_KEY = 'active_prompt';

type SyncQueueItem = {
  type: 'update' | 'delete';
  promptId: string;
  data?: Partial<Prompt>;
  timestamp: number;
};

function PromptsContent() {
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [newPrompt, setNewPrompt] = useState({ title: '', content: '' });
  const [editingPrompt, setEditingPrompt] = useState<Prompt | null>(null);
  const [showPromptForm, setShowPromptForm] = useState(false);
  const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' } | null>(null);
  const [syncQueue, setSyncQueue] = useState<SyncQueueItem[]>([]);
  const [activePromptId, setActivePromptId] = useState<string | null>(null);
  const { isAuthenticated } = useAuthenticationStatus();
  const nhost = useNhostClient();

  // Load prompts and active prompt from localStorage on init
  useEffect(() => {
    const storedPrompts = localStorage.getItem(STORAGE_KEY);
    const storedQueue = localStorage.getItem(SYNC_QUEUE_KEY);
    const storedActivePromptId = localStorage.getItem(ACTIVE_PROMPT_KEY);
    
    // Always load from localStorage first
    if (storedPrompts) {
      const allPrompts = JSON.parse(storedPrompts);
      setPrompts(allPrompts);
    }

    if (storedQueue && isAuthenticated) {
      setSyncQueue(JSON.parse(storedQueue));
    }
    if (storedActivePromptId) {
      setActivePromptId(storedActivePromptId);
    }
  }, [isAuthenticated]);

  // Save active prompt to localStorage whenever it changes
  useEffect(() => {
    if (activePromptId) {
      localStorage.setItem(ACTIVE_PROMPT_KEY, activePromptId);
    } else {
      localStorage.removeItem(ACTIVE_PROMPT_KEY);
    }
  }, [activePromptId]);

  // Handle setting active prompt
  const handleSetActivePrompt = (promptId: string) => {
    setActivePromptId(prevId => prevId === promptId ? null : promptId);
    setMessage({ 
      text: activePromptId === promptId 
        ? 'Prompt deactivated' 
        : 'Prompt set as active, restart conversation to use it', 
      type: 'success' 
    });
    setTimeout(() => setMessage(null), 3000);
  };

  // Save to localStorage whenever prompts change
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(prompts));
  }, [prompts]);

  // Save sync queue to localStorage only when authenticated
  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem(SYNC_QUEUE_KEY, JSON.stringify(syncQueue));
    }
  }, [syncQueue, isAuthenticated]);

  // Debounced sync function - only used when authenticated
  const syncToDatabase = useCallback(
    (promptToSync: Prompt) => {
      if (!isAuthenticated) return; // Skip sync if not authenticated
      
      const sync = async () => {
        try {
          const response = await nhost.graphql.request<UpdatePromptData>(
            `mutation UpdatePrompt($id: uuid!, $title: String!, $content: String!) {
              update_prompts_by_pk(
                pk_columns: { id: $id }
                _set: { title: $title, content: $content }
              ) {
                id
                title
                content
                user_id
                created_at
              }
            }`,
            {
              id: promptToSync.id,
              title: promptToSync.title,
              content: promptToSync.content
            }
          );

          if (response.error) {
            throw new Error(Array.isArray(response.error) 
              ? response.error[0]?.message 
              : response.error.message || 'Failed to sync prompt');
          }

          // Remove from sync queue after successful sync
          setSyncQueue(queue => queue.filter(item => 
            !(item.type === 'update' && item.promptId === promptToSync.id)
          ));

          // Update local state to remove dirty flag
          setPrompts(currentPrompts => 
            currentPrompts.map(p => 
              p.id === promptToSync.id ? { ...p, dirty: false } : p
            )
          );
        } catch (error) {
          console.error('Failed to sync prompt:', error);
          // Keep in sync queue for retry
        }
      };
      
      sync();
    },
    [isAuthenticated, nhost.graphql]
  );

  // Process sync queue periodically
  useEffect(() => {
    const interval = setInterval(() => {
      syncQueue.forEach(item => {
        if (item.type === 'update' && item.data) {
          const promptToSync = prompts.find(p => p.id === item.promptId);
          if (promptToSync && promptToSync.dirty) {
            syncToDatabase(promptToSync);
          }
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [syncQueue, prompts, syncToDatabase]);

  const handleSavePrompt = async () => {
    if (!newPrompt.title || !newPrompt.content) {
      setMessage({ text: 'Please fill in both title and content', type: 'error' });
      return;
    }

    const user = nhost.auth.getUser();
    const newPromptData: Prompt = {
      id: crypto.randomUUID(),
      title: newPrompt.title,
      content: newPrompt.content,
      user_id: user?.id || 'local',
      created_at: new Date().toISOString(),
    };

    setPrompts(prev => [...prev, newPromptData]);
    setNewPrompt({ title: '', content: '' });
    setShowPromptForm(false);

    if (isAuthenticated) {
      setSyncQueue(prev => [...prev, {
        type: 'update',
        promptId: newPromptData.id,
        data: newPromptData,
        timestamp: Date.now()
      }]);
    }

    setMessage({ text: 'Prompt saved successfully', type: 'success' });
    setTimeout(() => setMessage(null), 3000);
  };

  const handleEditPrompt = async (prompt: Prompt) => {
    if (!prompt.title || !prompt.content) {
      setMessage({ text: 'Please fill in both title and content', type: 'error' });
      return;
    }

    // Update local state immediately
    setPrompts(currentPrompts =>
      currentPrompts.map(p =>
        p.id === prompt.id
          ? { ...prompt, dirty: isAuthenticated }
          : p
      )
    );

    // Add to sync queue if authenticated
    if (isAuthenticated) {
      setSyncQueue(queue => [
        ...queue.filter(item => !(item.type === 'update' && item.promptId === prompt.id)),
        {
          type: 'update',
          promptId: prompt.id,
          data: { title: prompt.title, content: prompt.content },
          timestamp: Date.now()
        }
      ]);
    }

    setEditingPrompt(null);
    setShowPromptForm(false);
    setMessage({ 
      text: isAuthenticated ? 'Prompt updated! Syncing...' : 'Prompt updated!', 
      type: 'success' 
    });
    setTimeout(() => setMessage(null), 3000);
  };

  const handleDeletePrompt = async (promptId: string) => {
    if (!window.confirm('Are you sure you want to delete this prompt?')) {
      return;
    }

    const prompt = prompts.find(p => p.id === promptId);
    if (!prompt) return;

    if (isAuthenticated) {
      try {
        const response = await nhost.graphql.request<DeletePromptData>(
          `mutation DeletePrompt($id: uuid!) {
            delete_prompts_by_pk(id: $id) {
              id
            }
          }`,
          { 
            id: promptId
          }
        );

        if (response.error) {
          throw new Error(Array.isArray(response.error) 
            ? response.error[0]?.message 
            : response.error.message || 'Failed to delete prompt');
        }
      } catch (error) {
        console.error('Failed to delete prompt:', error);
        setMessage({ 
          text: `Failed to delete prompt: ${error instanceof Error ? error.message : 'Unknown error'}`,
          type: 'error'
        });
        return;
      }
    }

    setPrompts(prompts.filter(p => p.id !== promptId));
    setSyncQueue(queue => queue.filter(item => item.promptId !== promptId));
    setMessage({ text: 'Prompt deleted successfully!', type: 'success' });
    setTimeout(() => setMessage(null), 3000);
  };

  // Initial data fetch from DB and merge with local data
  useEffect(() => {
    if (isAuthenticated) {
      const user = nhost.auth.getUser();
      if (!user) return;

      nhost.graphql.request<PromptsData>(`
        query GetPrompts($user_id: uuid!) {
          prompts(where: { user_id: { _eq: $user_id } }) {
            id
            title
            content
            user_id
            created_at
          }
        }
      `, { user_id: user.id }).then(response => {
        if (response.error) {
          throw new Error(Array.isArray(response.error) 
            ? response.error[0]?.message 
            : response.error.message || 'GraphQL error occurred');
        }

        if (response.data?.prompts) {
          // Merge DB prompts with local prompts
          setPrompts(currentPrompts => {
            const dbPrompts = response.data!.prompts;
            const localPrompts = currentPrompts.filter(p => p.user_id === 'local');
            return [...dbPrompts, ...localPrompts];
          });
        }
      }).catch(error => {
        console.error('Failed to fetch prompts:', error);
      });
    }
  }, [isAuthenticated, nhost.graphql, nhost.auth]);

  return (
    <div className="space-y-6 p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Custom Instructions</h2>
        {!showPromptForm && !editingPrompt && (
          <button 
            className="btn btn-primary"
            onClick={() => setShowPromptForm(true)}
          >
            Create New Prompt
          </button>
        )}
      </div>

      {message && (
        <div className={cn(
          'p-3 rounded-lg mb-4',
          message.type === 'success' ? 'bg-success/20 text-success' : 'bg-error/20 text-error'
        )}>
          {message.text}
        </div>
      )}

      {(showPromptForm || editingPrompt) && (
        <div className="bg-base-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">
            {editingPrompt ? 'Edit Prompt' : 'Create New Prompt'}
          </h3>
          <div className="space-y-4">
            <input
              type="text"
              placeholder="Prompt Title"
              className="input input-bordered w-full"
              value={editingPrompt ? editingPrompt.title : newPrompt.title}
              onChange={(e) => editingPrompt 
                ? setEditingPrompt({ ...editingPrompt, title: e.target.value })
                : setNewPrompt({ ...newPrompt, title: e.target.value })
              }
            />
            <textarea
              placeholder="Prompt Content"
              className="textarea textarea-bordered w-full h-48"
              value={editingPrompt ? editingPrompt.content : newPrompt.content}
              onChange={(e) => editingPrompt
                ? setEditingPrompt({ ...editingPrompt, content: e.target.value })
                : setNewPrompt({ ...newPrompt, content: e.target.value })
              }
            />
            <div className="flex gap-2">
              <button 
                className="btn btn-primary"
                onClick={editingPrompt ? () => handleEditPrompt(editingPrompt) : handleSavePrompt}
              >
                {editingPrompt ? 'Update Prompt' : 'Create Prompt'}
              </button>
              <button 
                className="btn btn-ghost"
                onClick={() => {
                  setShowPromptForm(false);
                  setEditingPrompt(null);
                  setNewPrompt({ title: '', content: '' });
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="bg-base-100 p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">Your Prompts</h3>
        <div className="space-y-2">
          {prompts.map((prompt) => (
            <div 
              key={prompt.id} 
              className={cn(
                "flex items-center justify-between p-2 rounded-lg transition-colors",
                activePromptId === prompt.id 
                  ? "bg-primary/5" 
                  : "hover:bg-base-200"
              )}
            >
              <h4 className="font-medium truncate flex-1">{prompt.title}</h4>
              <div className="flex gap-2 items-center">
                {prompt.dirty && (
                  <span className="text-xs text-base-content/70">Syncing...</span>
                )}
                <button
                  className={cn(
                    "btn btn-sm",
                    activePromptId === prompt.id 
                      ? "btn-primary" 
                      : "btn-ghost"
                  )}
                  onClick={() => handleSetActivePrompt(prompt.id)}
                >
                  {activePromptId === prompt.id ? 'Active' : 'Set Active'}
                </button>
                <button
                  className="btn btn-sm btn-ghost"
                  onClick={() => setEditingPrompt(prompt)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-sm btn-ghost text-error"
                  onClick={() => handleDeletePrompt(prompt.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PromptsContent; 