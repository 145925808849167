import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { useAuthenticationStatus } from '@nhost/react';
import PromptsContent from '../components/prompts/PromptsContent';
import { useVersionCheck } from '../hooks/use-version-check';
import { VoiceName, AVAILABLE_VOICES, getStoredVoice, setStoredVoice } from '../lib/voice-manager';

type Tab = 'instructions' | 'prompts' | 'settings';

function Settings() {
  const [activeTab, setActiveTab] = useState<Tab>('instructions');
  const { isAuthenticated } = useAuthenticationStatus();
  const { versionInfo } = useVersionCheck();
  const [selectedVoice, setSelectedVoice] = useState<VoiceName>(getStoredVoice());
  
  // Get the control type from URL parameter
  const params = new URLSearchParams(window.location.search);
  const controlType = params.get('control') || 'gamepad';

  useEffect(() => {
    setStoredVoice(selectedVoice);
  }, [selectedVoice]);

  return (
    <div className="relative min-h-screen pb-8">
      <div className="mb-6">
        <div className="border-b border-base-300">
          <nav className="-mb-px flex space-x-8">
            <button
              onClick={() => setActiveTab('instructions')}
              className={cn(
                'py-4 px-1 border-b-2 text-sm',
                activeTab === 'instructions'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-base-content hover:text-base-content hover:border-base-300'
              )}
            >
              Instructions
            </button>
            
            <button
              onClick={() => setActiveTab('prompts')}
              className={cn(
                'py-4 px-1 border-b-2 text-sm',
                activeTab === 'prompts'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-base-content hover:text-base-content hover:border-base-300'
              )}
            >
              Prompts
            </button>

            <button
              onClick={() => setActiveTab('settings')}
              className={cn(
                'py-4 px-1 border-b-2 text-sm',
                activeTab === 'settings'
                  ? 'border-primary text-primary'
                  : 'border-transparent text-base-content hover:text-base-content hover:border-base-300'
              )}
            >
              Settings
            </button>
          </nav>
        </div>
      </div>

      {activeTab === 'instructions' && (
        <div className="space-y-4 h-[calc(100vh-140px)] overflow-y-auto">
          <div className="bg-base-100 p-4 rounded-lg shadow">
            <div className="space-y-4">
              <div className="prose text-sm text-base-content">
                <ul className="list-disc pl-5 space-y-2">
                <li>Before starting, make sure to:</li>
                  <ul className="list-disc pl-5 space-y-1">
                    <li>Click the Play button on the sidebar to start the AI</li>
                    <li>Enable your microphone to give voice commands</li>
                    <li>Enable your camera to give visual commands</li>
                  </ul>

                  {(controlType === 'gamepad') && (
                    <>
                      <li>Try gamepad commands like:</li>
                      <ul className="list-disc pl-5 space-y-1">
                        <li>"Press the A button"</li>
                        <li>"Hold the right trigger"</li>
                        <li>"Move using the left stick"</li>
                        <li>"Reset the gamepad"</li>
                        <li>"Press the Y button for 2 seconds then release and press the A button"</li>
                      </ul>
                      
                      <li>Buttons automatically release after 200ms unless specified otherwise</li>
                      <li>You can control the gamepad using various button commands:</li>
                      <ul className="list-disc pl-5 space-y-1">
                        <li>Buttons: A, B, X, Y</li>
                        <li>Sticks: Left Stick Up/Down/Left/Right, Right Stick Up/Down/Left/Right</li>
                        <li>Shoulder buttons: Left Shoulder (LB/L1), Right Shoulder (RB/R1)</li>
                        <li>Thumbsticks: Left Thumb (LT/L3), Right Thumb (RT/R3)</li>
                        <li>Triggers: Left Trigger (LT/L2), Right Trigger (RT/R2)</li>
                        <li>D-pad: D-pad Up (UP), D-pad Down (DOWN), D-pad Left (LEFT), D-pad Right (RIGHT)</li>
                        <li>System buttons: Back, Start, Guide</li>
                      </ul>
                    </>
                  )}

                  {(controlType === 'keyboard' || controlType === 'keyboard-and-mouse') && (
                    <>
                      <li>Try keyboard and mouse commands like:</li>
                      <ul className="list-disc pl-5 space-y-1">
                        <li>"Press the spacebar"</li>
                        <li>"Hold the W key"</li>
                        <li>"Click the left mouse button"</li>
                        <li>"Press Ctrl+C"</li>
                      </ul>

                      <li>Keys automatically release after 200ms unless specified otherwise</li>
                      <li>Available keyboard and mouse controls:</li>
                      <ul className="list-disc pl-5 space-y-1">
                        <li>Letters: A through Z</li>
                        <li>Numbers: 0-9 and Numpad 0-9</li>
                        <li>Special keys: Backspace, Delete, Enter, Tab, Escape</li>
                        <li>Arrow keys: Up, Down, Left, Right</li>
                        <li>Navigation: Home, End, Page Up, Page Down</li>
                        <li>Function keys: F1 through F12</li>
                        <li>Modifier keys: Alt, Control, Shift, Space, Windows</li>
                        <li>Media keys: Play, Pause, Mute</li>
                        <li>Mouse: Left Click, Middle Click, Right Click</li>
                      </ul>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'prompts' && (
        <div className="h-[calc(100vh-140px)] overflow-y-auto">
          <PromptsContent />
        </div>
      )}

      {activeTab === 'settings' && (
        <div className="space-y-4 h-[calc(100vh-140px)] overflow-y-auto">
          <div className="bg-base-100 p-4 rounded-lg shadow">
            <div className="space-y-8">
              {/* Voice Settings Section */}
              <div className="space-y-4">
                <h2 className="text-lg font-semibold text-base-content">Voice Settings</h2>
                <div className="prose text-sm text-base-content">
                  <p>Select the voice that will be used for AI responses:</p>
                  <div className="mt-4 space-y-2">
                    {AVAILABLE_VOICES.map((voice) => (
                      <label key={voice} className="flex items-center space-x-2 cursor-pointer">
                        <input
                          type="radio"
                          name="voice"
                          value={voice}
                          checked={selectedVoice === voice}
                          onChange={(e) => setSelectedVoice(e.target.value as VoiceName)}
                          className="radio radio-primary"
                        />
                        <span>{voice}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="absolute bottom-0 left-0 w-full p-4 text-center text-xs text-base-content opacity-50">
        Version {versionInfo?.currentVersion}
      </div>
    </div>
  );
}

export default Settings; 