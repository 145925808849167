import { useState, useEffect, useCallback } from 'react';
import { getLatestVersion, VersionResponse } from '../lib/version-manager';

interface UseVersionCheckOptions {
  checkOnMount?: boolean;
}

export function useVersionCheck(options: UseVersionCheckOptions = {}) {
  const { checkOnMount = true } = options;

  const [versionInfo, setVersionInfo] = useState<VersionResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const checkVersion = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const info = await getLatestVersion();
      setVersionInfo(info);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to check version'));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (checkOnMount) {
      checkVersion();
    }
  }, [checkOnMount, checkVersion]);

  return {
    versionInfo,
    loading,
    error,
    checkVersion
  };
} 