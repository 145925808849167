export type VoiceName = 'Aoede' | 'Charon' | 'Fenrir' | 'Kore' | 'Puck';

const VOICE_STORAGE_KEY = 'voice_settings';

export const AVAILABLE_VOICES: VoiceName[] = ['Aoede', 'Charon', 'Fenrir', 'Kore', 'Puck'];

export function getStoredVoice(): VoiceName {
  const stored = localStorage.getItem(VOICE_STORAGE_KEY);
  return (stored as VoiceName) || 'Fenrir'; // Default to Fenrir
}

export function setStoredVoice(voice: VoiceName) {
  localStorage.setItem(VOICE_STORAGE_KEY, voice);
} 