import { useEffect, useState, memo } from 'react';
import { useLiveAPIContext } from '../../contexts/LiveAPIContext';
import { type FunctionDeclaration, SchemaType } from "@google/generative-ai";
import { ToolCall } from "../../multimodal-live-types";
import { useActivePrompt } from '../../hooks/use-active-prompt';
import { getStoredVoice } from '../../lib/voice-manager';
import { getEffectiveApiKey } from '../../lib/api-key-manager';
import cn from "classnames";

// Unified function declaration for gamepad operations
const unifiedGamepadDeclaration: FunctionDeclaration = {
  name: "gamepad_control",
  description: "Controls gamepad operations including getting state and sending commands.",
  parameters: {
    type: SchemaType.OBJECT,
    properties: {
      operation: {
        type: SchemaType.STRING,
        enum: ["get_state", "send_events"],
        description: "The operation to perform: 'get_state' to get gamepad state or 'send_events' to send commands",
      },
      events: {
        type: SchemaType.ARRAY,
        description: "Array of button events, trigger events, or reset command to send (only for send_events operation)",
        items: {
          type: SchemaType.OBJECT,
          properties: {
            type: {
              type: SchemaType.STRING,
              enum: ["button", "trigger", "reset"],
              description: "Event type ('button' for digital inputs, 'trigger' for analog triggers, or 'reset')",
            },
            code: {
              type: SchemaType.STRING,
              enum: [
                "A", "B", "X", "Y",
                "LEFT_SHOULDER", "RIGHT_SHOULDER",
                "LEFT_THUMB", "RIGHT_THUMB",
                "BACK", "START", "GUIDE",
                "DPAD_UP", "DPAD_DOWN", "DPAD_LEFT", "DPAD_RIGHT",
                "LEFT_STICK_UP", "LEFT_STICK_DOWN", "LEFT_STICK_LEFT", "LEFT_STICK_RIGHT",
                "RIGHT_STICK_UP", "RIGHT_STICK_DOWN", "RIGHT_STICK_LEFT", "RIGHT_STICK_RIGHT",
                "leftTrigger", "rightTrigger"
              ],
              description: "Button or trigger code. For triggers, use 'leftTrigger' or 'rightTrigger' with type: 'trigger' and value: 0-1",
            },
            value: {
              type: SchemaType.NUMBER,
              description: "Input state: For buttons: 1 (pressed) or 0 (released), For triggers: 0.0 to 1.0 for pressure amount",
            },
            delay: {
              type: SchemaType.NUMBER,
              description: "Delay in milliseconds before auto-releasing the button (omit to maintain pressed state)",
            },
          },
          required: ["type"],
        },
      },
    },
    required: ["operation"],
  },
};

function GamepadComponent() {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const { client, setConfig } = useLiveAPIContext();
  const { activePrompt } = useActivePrompt();
  
  // WebSocket connection setup
  useEffect(() => {
    const apiKey = getEffectiveApiKey();
    if (!apiKey) {
      console.warn('No API key available. Please set an API key in Settings or provide one in the environment.');
      return;
    }

    const websocket = new WebSocket('ws://127.0.0.1:13123');
    setWs(websocket);
    return () => {
      websocket.close();
    };
  }, []);

  // Configuration setup
  useEffect(() => {
    if (setConfig) {
      const apiKey = getEffectiveApiKey();
      if (!apiKey) {
        console.warn('No API key available. Please set an API key in Settings or provide one in the environment.');
        return;
      }

      setConfig({
        model: "models/gemini-2.0-flash-exp",
        generationConfig: {
          temperature: 0.5,
          topP: 0.9,
          speechConfig: {
            voiceConfig: {
              prebuiltVoiceConfig: {
                voiceName: getStoredVoice()
              }
            }
          }
        },
        systemInstruction: {
          parts: [
            {
              text: `
You are an **autonomous gaming assistant** controlling a character in real time. 

You can control the gamepad using the unified gamepad_control function.

**Operations**:

1. Get Gamepad State:
\`\`\`
{
  "operation": "get_state"
}
\`\`\`

2. Send Button Events:
\`\`\`
{
  "operation": "send_events",
  "events": [
    {"type": "button", "code": "A", "value": true}
  ]
}
\`\`\`

3. Send Reset Command:
\`\`\`
{
  "operation": "send_events",
  "events": [
    {"type": "reset"}
  ]
}
\`\`\`

**Valid Button Controls**:
All button events must include:
- type: "button"
- code: valid button code
- value: boolean (true = press, false = release)

**Available button codes**:
- A, B, X, Y
- LEFT_SHOULDER, RIGHT_SHOULDER
- LEFT_TRIGGER, RIGHT_TRIGGER
- LEFT_THUMB, RIGHT_THUMB
- BACK, START, GUIDE
- DPAD_UP, DPAD_DOWN, DPAD_LEFT, DPAD_RIGHT
- LEFT_STICK_UP, LEFT_STICK_DOWN, LEFT_STICK_LEFT, LEFT_STICK_RIGHT
- RIGHT_STICK_UP, RIGHT_STICK_DOWN, RIGHT_STICK_LEFT, RIGHT_STICK_RIGHT

**Button Press Behavior**:
- Default behavior: Buttons auto-release after 200ms
- Specify custom delay: Add "delay" parameter in milliseconds
- Maintain press: Omit the delay parameter

Example with delay:
\`\`\`
{
  "operation": "send_events",
  "events": [
    {"type": "button", "code": "A", "value": true, "delay": 500}  // Press A for 500ms
  ]
}
\`\`\`

Example maintaining press:
\`\`\`
{
  "operation": "send_events",
  "events": [
    {"type": "button", "code": "A", "value": true}  // Press and maintain A
  ]
}
\`\`\`

**Guidelines**:
1. Always send separate press/release events for each button action
2. Structure multiple button actions as multiple events in one call
3. Remember to release buttons unless intentionally holding them
4. Use get_state to check current gamepad status

Additional Instructions:
${activePrompt ? `\n\n"${activePrompt.content}"\n\n` : ''}
              `,
            },
          ],
        },
        tools: [
          {
            functionDeclarations: [unifiedGamepadDeclaration]
          }
        ],
      });
    }
  }, [setConfig, activePrompt]);

  // Tool calls handler
  useEffect(() => {
    const onToolCall = async (toolCall: ToolCall) => {
      for (const fc of toolCall.functionCalls) {
        if (fc.name === unifiedGamepadDeclaration.name) {
          const args = fc.args as {
            operation: string;
            events?: Array<{
              type: string;
              code?: string;
              value?: boolean | number;
              delay?: number;
            }>;
          };

          if (args.operation === 'get_state') {
            if (ws && ws.readyState === WebSocket.OPEN) {
              try {
                // Wait for state update
                const state = await new Promise((resolve, reject) => {
                  ws.send(JSON.stringify({ type: "getState" }));
                  
                  const handler = (event: MessageEvent) => {
                    try {
                      // Parse the message - it might be a string twice over
                      let message = event.data;
                      if (typeof message === 'string') {
                        message = JSON.parse(message);
                        // It might need to be parsed twice
                        if (typeof message === 'string') {
                          message = JSON.parse(message);
                        }
                      }
                      
                      if (message && message.type === 'state') {
                        ws.removeEventListener('message', handler);
                        resolve(message.data);
                      }
                    } catch (error) {
                      reject(error);
                    }
                  };
                  
                  ws.addEventListener('message', handler);
                  
                  setTimeout(() => {
                    ws.removeEventListener('message', handler);
                    reject(new Error('Timeout waiting for gamepad state'));
                  }, 5000);
                });
              
                client.sendToolResponse({
                  functionResponses: [{
                    response: { state },
                    id: fc.id,
                  }],
                });
              } catch (error) {
                client.sendToolResponse({
                  functionResponses: [{
                    response: { error: (error as Error).message },
                    id: fc.id,
                  }],
                });
              }
            }
          } else if (args.operation === 'send_events' && args.events) {
            if (ws && ws.readyState === WebSocket.OPEN) {
              for (const event of args.events) {
                // Send the initial event
                ws.send(JSON.stringify({ events: [event] }));

                // Handle button events with auto-release
                if (event.type === 'button' && event.value === true && event.code) {
                  const delayMs = event.delay ?? 200;
                  setTimeout(() => {
                    ws.send(JSON.stringify({
                      events: [{
                        type: event.type,
                        code: event.code,
                        value: false
                      }]
                    }));
                  }, delayMs);
                }
                
                // Handle trigger events
                if (event.type === 'trigger' && event.code && 
                    (event.code === 'leftTrigger' || event.code === 'rightTrigger')) {
                  // Ensure value is between 0 and 1
                  const triggerValue = Math.max(0, Math.min(1, event.value as number));
                  ws.send(JSON.stringify({
                    events: [{
                      type: 'trigger',
                      code: event.code,
                      value: triggerValue
                    }]
                  }));
                }
              }
            }
            client.sendToolResponse({
              functionResponses: [{
                response: { success: true },
                id: fc.id,
              }],
            });
          }
        }
      }
    };

    if (client) {
      client.on("toolcall", onToolCall);
      return () => {
        client.off("toolcall", onToolCall);
      };
    }
  }, [client, ws]);

  return (

      <button 
        className={cn("action-button gamepad-button", {
          "connected": ws?.readyState === WebSocket.OPEN
        })}
        title={ws?.readyState === WebSocket.OPEN ? 'Gamepad Connected' : 'Gamepad Disconnected'}
      >
        <span className={cn("material-symbols-outlined", {
          "filled": ws?.readyState === WebSocket.OPEN
        })}>
          sports_esports
        </span>
      </button>

  );
}

export const Gamepad = memo(GamepadComponent);
