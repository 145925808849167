import { useEffect, useState, memo } from 'react';
import { useLiveAPIContext } from '../../contexts/LiveAPIContext';
import { type FunctionDeclaration, SchemaType } from "@google/generative-ai";
import { ToolCall } from "../../multimodal-live-types";
import { useActivePrompt } from '../../hooks/use-active-prompt';
import { getStoredVoice } from '../../lib/voice-manager';
import { getEffectiveApiKey } from '../../lib/api-key-manager';
import cn from "classnames";

// Unified function declaration for keyboard and mouse operations
const unifiedKeyboardMouseDeclaration: FunctionDeclaration = {
  name: "keyboard_mouse_control",
  description: "Controls keyboard and mouse operations including getting state and sending commands.",
  parameters: {
    type: SchemaType.OBJECT,
    properties: {
      operation: {
        type: SchemaType.STRING,
        enum: ["send_events"],
        description: "The operation to perform: 'send_events' to send commands",
      },
      events: {
        type: SchemaType.ARRAY,
        description: "Array of keyboard events, mouse button events, mouse movement, or reset command",
        items: {
          type: SchemaType.OBJECT,
          properties: {
            type: {
              type: SchemaType.STRING,
              enum: ["keyboard", "mouseButton", "mouseMove", "reset"],
              description: "Event type ('keyboard' for key inputs, 'mouseButton' for clicks, 'mouseMove' for movement, or 'reset')",
            },
            code: {
              type: SchemaType.STRING,
              enum: [
                // Mouse buttons
                "leftClick", "middleClick", "rightClick",
                // Letters
                "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
                "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
                // Numbers
                "0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
                // Numpad
                "numpad_0", "numpad_1", "numpad_2", "numpad_3", "numpad_4",
                "numpad_5", "numpad_6", "numpad_7", "numpad_8", "numpad_9",
                // Special keys
                "backspace", "delete", "enter", "tab", "escape",
                "up", "down", "left", "right",
                "home", "end", "pageup", "pagedown",
                // Function keys
                "f1", "f2", "f3", "f4", "f5", "f6",
                "f7", "f8", "f9", "f10", "f11", "f12",
                // Modifier keys
                "alt", "control", "shift", "space", "windows",
                // Media keys
                "play", "pause", "mute",
                // Special
                "fn"
              ],
              description: "The specific key code or mouse button to control"
            },
            value: {
              type: SchemaType.BOOLEAN,
              description: "Input state: true (pressed) or false (released) for keys and mouse buttons",
            },
            x: {
              type: SchemaType.NUMBER,
              description: "X coordinate for mouse movement (only for mouseMove type)",
            },
            y: {
              type: SchemaType.NUMBER,
              description: "Y coordinate for mouse movement (only for mouseMove type)",
            },
            delay: {
              type: SchemaType.NUMBER,
              description: "Delay in milliseconds before auto-releasing the key/button (omit to maintain pressed state)",
            },
          },
          required: ["type"],
        },
      },
    },
    required: ["operation"],
  },
};

function KeyboardAndMouseComponent() {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const { client, setConfig } = useLiveAPIContext();
  const { activePrompt } = useActivePrompt();
  
  // WebSocket connection setup
  useEffect(() => {
    const apiKey = getEffectiveApiKey();
    if (!apiKey) {
      console.warn('No API key available. Please set an API key in Settings or provide one in the environment.');
      return;
    }

    const websocket = new WebSocket('ws://127.0.0.1:13123');
    setWs(websocket);
    return () => {
      websocket.close();
    };
  }, []);

  // Configuration setup
  useEffect(() => {
    if (setConfig) {
      const apiKey = getEffectiveApiKey();
      if (!apiKey) {
        console.warn('No API key available. Please set an API key in Settings or provide one in the environment.');
        return;
      }

      setConfig({
        model: "models/gemini-2.0-flash-exp",
        generationConfig: {
          temperature: 0.5,
          topP: 0.9,
          speechConfig: {
            voiceConfig: {
              prebuiltVoiceConfig: {
                voiceName: getStoredVoice()
              }
            }
          }
        },
        systemInstruction: {
          parts: [
            {
              text: `
You are an **autonomous assistant** controlling keyboard and mouse inputs in real time. 

You can control the keyboard and mouse using the keyboard_mouse_control function.

**Event Types and Examples**:

1. Mouse Button Events:
\`\`\`json
{
  "operation": "send_events",
  "events": [
    {
      "type": "mouseButton",
      "code": "leftClick",  // Can be: leftClick, middleClick, rightClick
      "value": true,        // true for press, false for release
      "delay": 100         // Optional: auto-release after 100ms
    }
  ]
}
\`\`\`

2. Keyboard Events:
\`\`\`json
{
  "operation": "send_events",
  "events": [
    {
      "type": "keyboard",
      "code": "A",         // Any letter in caps (A-Z), number (0-9), or special key
      "value": true,       // true for press, false for release
      "delay": 50         // Optional: auto-release after 50ms
    }
  ]
}
\`\`\`

3. Mouse Movement:
\`\`\`json
{
  "operation": "send_events",
  "events": [
    {
      "type": "mouseMove",
      "x": 100,           // X coordinate on screen
      "y": 200           // Y coordinate on screen
    }
  ]
}
\`\`\`

4. Reset All Inputs:
\`\`\`json
{
  "operation": "send_events",
  "events": [
    {
      "type": "reset"
    }
  ]
}
\`\`\`

**Available Keys**:
- Letters: A through Z, uppercase
- Numbers: 0 through 9
- Numpad: numpad_0 through numpad_9
- Special Keys: backspace, delete, enter, tab, escape
- Arrow Keys: up, down, left, right
- Navigation: home, end, pageup, pagedown
- Function Keys: f1 through f12
- Modifiers: alt, control, shift, space, windows
- Media: play, pause, mute
- Special: fn

**Guidelines**:
1. Always send separate press/release events for each key/button action
2. Structure multiple actions as multiple events in one call
3. Remember to release keys/buttons unless intentionally holding them
4. Use get_state to check current input status

Additional Instructions:
${activePrompt ? `\n\n"${activePrompt.content}"\n\n` : ''}
              `,
            },
          ],
        },
        tools: [
          {
            functionDeclarations: [unifiedKeyboardMouseDeclaration]
          }
        ],
      });
    }
  }, [setConfig, activePrompt]);

  // Tool calls handler
  useEffect(() => {
    const onToolCall = async (toolCall: ToolCall) => {
      for (const fc of toolCall.functionCalls) {
        if (fc.name === unifiedKeyboardMouseDeclaration.name) {
          const args = fc.args as {
            operation: string;
            events?: Array<{
              type: string;
              code?: string;
              value?: boolean;
              x?: number;
              y?: number;
              delay?: number;
            }>;
          };

          if (args.operation === 'send_events' && args.events) {
            if (ws && ws.readyState === WebSocket.OPEN) {
              for (const event of args.events) {
                // Send the initial event
                ws.send(JSON.stringify({ events: [event] }));

                // Handle button events with auto-release
                if ((event.type === 'keyboard' || event.type === 'mouseButton') && 
                    event.value === true && event.code) {
                  const delayMs = event.delay ?? 100;
                  setTimeout(() => {
                    ws.send(JSON.stringify({
                      events: [{
                        type: event.type,
                        code: event.code,
                        value: false
                      }]
                    }));
                  }, delayMs);
                }
                
                // Handle mouse movement events
                if (event.type === 'mouseMove' && 
                    typeof event.x === 'number' && 
                    typeof event.y === 'number') {
                  ws.send(JSON.stringify({
                    events: [{
                      type: 'mouseMove',
                      x: event.x,
                      y: event.y
                    }]
                  }));
                }
              }
            }
            client.sendToolResponse({
              functionResponses: [{
                response: { success: true },
                id: fc.id,
              }],
            });
          }
        }
      }
    };

    if (client) {
      client.on("toolcall", onToolCall);
      return () => {
        client.off("toolcall", onToolCall);
      };
    }
  }, [client, ws]);

  return (
    <button 
      className={cn("action-button keyboard-mouse-button", {
        "connected": ws?.readyState === WebSocket.OPEN
      })}
      title={ws?.readyState === WebSocket.OPEN ? 'Keyboard & Mouse Connected' : 'Keyboard & Mouse Disconnected'}
    >
      <span className={cn("material-symbols-outlined", {
        "filled": ws?.readyState === WebSocket.OPEN
      })}>
        keyboardmouse
      </span>
    </button>
  );
}

export const KeyboardAndMouse = memo(KeyboardAndMouseComponent); 