import { useState, useEffect } from 'react';

const ACTIVE_PROMPT_KEY = 'active_prompt';
const PROMPTS_DATA_KEY = 'prompts_data';

export type Prompt = {
  id: string;
  title: string;
  content: string;
  user_id: string;
  created_at: string;
};

export function useActivePrompt() {
  const [activePrompt, setActivePrompt] = useState<Prompt | null>(null);

  useEffect(() => {
    const loadActivePrompt = () => {
      const activePromptId = localStorage.getItem(ACTIVE_PROMPT_KEY);
      const storedPrompts = localStorage.getItem(PROMPTS_DATA_KEY);
      
      if (activePromptId && storedPrompts) {
        const prompts: Prompt[] = JSON.parse(storedPrompts);
        const prompt = prompts.find(p => p.id === activePromptId);
        setActivePrompt(prompt || null);
      } else {
        setActivePrompt(null);
      }
    };

    loadActivePrompt();

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === ACTIVE_PROMPT_KEY || e.key === PROMPTS_DATA_KEY) {
        loadActivePrompt();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return { 
    activePrompt
  };
} 