import { useRef, useState, useEffect } from "react";
import "./App.scss";
import { LiveAPIProvider } from "./contexts/LiveAPIContext";
import SidePanel from "./components/side-panel/SidePanel";
import ControlTray from "./components/control-tray/ControlTray";
import cn from "classnames";
import { NhostClient } from '@nhost/nhost-js';
import { NhostProvider, useUserData, useAuthenticationStatus } from '@nhost/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Settings from './pages/Settings';
import posthog from 'posthog-js';
import SignIn from './pages/SignIn';
import { useVersionCheck } from './hooks/use-version-check';

const API_KEY = process.env.REACT_APP_GEMINI_API_KEY as string;
const POSTHOG_API_KEY = process.env.REACT_APP_POSTHOG_API_KEY;
const POSTHOG_HOST = process.env.REACT_APP_POSTHOG_HOST || 'https://app.posthog.com';

if (typeof API_KEY !== "string") {
  throw new Error("set REACT_APP_GEMINI_APIK_KEY in .env");
}

if (!POSTHOG_API_KEY) {
  console.warn("PostHog API key not found. Analytics will be disabled.");
} else {
  // Initialize PostHog
  posthog.init(POSTHOG_API_KEY, {
    api_host: POSTHOG_HOST,
    loaded: (posthog) => {
    },
  });
}

const host = "generativelanguage.googleapis.com";
const uri = `wss://${host}/ws/google.ai.generativelanguage.v1alpha.GenerativeService.BidiGenerateContent`;

const nhost = new NhostClient({
  authUrl: 'https://auth.playability.gg/v1',
  storageUrl: 'https://mluiqtdkrlihnaupsdoj.storage.eu-central-1.nhost.run',
  graphqlUrl: 'https://hasura.playability.gg/v1/graphql',
  functionsUrl: 'https://functions.playability.gg/v1'
});

function MainPage({ isWidget, isTransparent }: { isWidget: boolean; isTransparent: boolean }) {
  return <AppContent isWidget={isWidget} isTransparent={isTransparent} />;
}

function AppContent({ isWidget, isTransparent }: { isWidget: boolean; isTransparent: boolean }) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAudioOutputMuted, setIsAudioOutputMuted] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const { isAuthenticated, isLoading } = useAuthenticationStatus();
  const user = useUserData();
  const { versionInfo } = useVersionCheck();
  
  useEffect(() => {
    if (accessToken && refreshToken) {
      nhost.auth.refreshSession(refreshToken)
        .then(response => {
          if (response.error) {
            console.error('Failed to refresh session:', response.error);
          } else {
            console.log('Session refreshed successfully:', response);
          }
        });
    }
  }, [accessToken, refreshToken]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.type) {
        switch (event.data.type) {
          case 'NHOST_AUTH':
            setAccessToken(event.data.token);
            setRefreshToken(event.data.refreshToken);
            break;
            
          case 'SCREEN_CAPTURE_CONTROL':
            // Handle screen capture control
            break;
            
          case 'CONNECTION_STATUS':
            // Handle connection status
            break;
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    console.log('Auth status:', { isAuthenticated, isLoading });
    console.log('Current user data:', user);
    
    // Add PostHog identification when user is authenticated
    if (POSTHOG_API_KEY && isAuthenticated && user?.email) {
      posthog.identify(user.id, {
        email: user.email,
      });
    }
  }, [isAuthenticated, isLoading, user]);

  return (
    <div className={cn("App", { 
      "widget-mode": isWidget
    })}>
      <LiveAPIProvider url={uri} apiKey={API_KEY}>
        <div className="streaming-console">
          {!isWidget && (
            <button 
              className="btn btn-circle btn-ghost bg-base-300 text-base-content hover:bg-base-200 fixed top-4 left-4 z-50"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              {isSidebarOpen ? '✕' : '☰'}
            </button>
          )}

          {!isWidget && ( 
            <div className="fixed top-4 right-4 z-50 flex items-center gap-2">
              <span className="text-xs text-base-content opacity-50">
                v{versionInfo?.currentVersion}
              </span>
              <button 
                className={cn(
                  "btn gap-2",
                  isAuthenticated ? "btn-primary" : "btn-ghost bg-base-300 hover:bg-base-200"
                )}
              >
                <span className="material-symbols-outlined filled">
                  {isAuthenticated ? 'lock' : 'lock_open'}
                </span>
                <span className="text-base-content">
                  {isLoading ? 'Loading...' : 
                   isAuthenticated ? (user?.email || 'No email') : 
                   'Not Connected'}
                </span>
              </button>
            </div>
          )}

          {!isWidget && (
            <div className={cn(
              'transition-transform duration-300 ease-in-out',
              'fixed left-0 top-0',
              'h-full z-40 bg-base-300',
              {
                '-translate-x-full': !isSidebarOpen,
                'translate-x-0': isSidebarOpen
              }
            )}>
              <SidePanel />
            </div>
          )}

          <main>
            <div className={cn("main-app-area", {
              "widget-mode-container": isWidget
            })}>
              {!isWidget && (
                <video
                  className={cn("stream", {
                    hidden: !videoRef.current || !videoStream
                  })}
                  ref={videoRef}
                  autoPlay
                  playsInline
                  muted={isAudioOutputMuted}
                  style={{ height: 'min-content' }}
                />
              )}
            </div>

            <ControlTray
              className={cn("bg-base-300", { "widget-controls": isWidget })}
              videoRef={videoRef}
              supportsVideo={true}
              onVideoStreamChange={setVideoStream}
              isAudioOutputMuted={isAudioOutputMuted}
              onAudioOutputMuteToggle={() => setIsAudioOutputMuted(!isAudioOutputMuted)}
              widgetVideo={isWidget ? (
                <div className="stream-button">
                  <video
                    className="widget-stream"
                    ref={videoRef}
                    autoPlay
                    playsInline
                    muted={isAudioOutputMuted}
                  />
                </div>
              ) : undefined}
            />
          </main>
        </div>
      </LiveAPIProvider>
    </div>
  );
}

function App() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const theme = params.get('theme');
    // Set theme based on URL parameter, defaulting to dark if not specified
    document.documentElement.setAttribute(
      'data-theme',
      theme === 'light' ? 'light' : 'dark'
    );
  }, []);

  // Add page view tracking
  useEffect(() => {
    if (POSTHOG_API_KEY) {
      // Track page views
      const handleLocationChange = () => {
        posthog.capture('$pageview');
      };

      window.addEventListener('popstate', handleLocationChange);
      // Initial page load
      handleLocationChange();

      return () => window.removeEventListener('popstate', handleLocationChange);
    }
  }, []);

  const params = new URLSearchParams(window.location.search);
  const isWidget = params.get('widget') === 'true';
  const isTransparent = params.get('transparent') !== 'false';

  return (
    <NhostProvider nhost={nhost}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage isWidget={isWidget} isTransparent={isTransparent} />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
      </BrowserRouter>
    </NhostProvider>
  );
}

export default App;
