import { memo } from 'react';
import { Gamepad } from '../gamepad/Gamepad';
import { KeyboardAndMouse } from '../keyboard-and-mouse/KeyboardAndMouse';

function DynamicControlsComponent() {
  // Get the control type from URL parameter
  const params = new URLSearchParams(window.location.search);
  const controlType = params.get('control') || 'gamepad'; // Default to gamepad if not specified

  // Render the appropriate control component based on the URL parameter
  switch (controlType.toLowerCase()) {  
    case 'keyboard':
    case 'keyboard-and-mouse':
      return <KeyboardAndMouse />;
    case 'gamepad':
    default:
      return <Gamepad />;
  }
}

export const DynamicControls = memo(DynamicControlsComponent); 