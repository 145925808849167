const API_KEY_STORAGE_KEY = 'api_key_settings';

export function getStoredApiKey(): string {
  return localStorage.getItem(API_KEY_STORAGE_KEY) || '';
}

export function getEffectiveApiKey(): string {
  const storedKey = getStoredApiKey();
  return storedKey || process.env.REACT_APP_GEMINI_API_KEY || '';
}

export function setStoredApiKey(apiKey: string) {
  if (!apiKey.trim()) {
    // If empty or just whitespace, remove from localStorage to use env variable
    localStorage.removeItem(API_KEY_STORAGE_KEY);
  } else {
    localStorage.setItem(API_KEY_STORAGE_KEY, apiKey);
  }
} 