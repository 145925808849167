import React, { useState, useEffect } from 'react';
import { useSignInEmailPassword } from '@nhost/react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const { signInEmailPassword, isLoading, isSuccess, isError, error } = useSignInEmailPassword();
  const navigate = useNavigate();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.type) {
        switch (event.data.type) {
          case 'NHOST_AUTH':
            setAccessToken(event.data.token);
            setRefreshToken(event.data.refreshToken);
            navigate('/');
            break;
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const result = await signInEmailPassword(email, password);
    if (result.isSuccess) {
      navigate('/');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-base-200">
      <div className="max-w-md w-full space-y-8 p-8 bg-base-100 rounded-lg shadow-lg">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-base-content">
            Sign in to your account
          </h2>
        </div>

        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input input-bordered w-full"
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input input-bordered w-full"
                placeholder="Password"
              />
            </div>
          </div>

          {isError && (
            <div className="bg-error/20 text-error rounded-lg p-4 text-sm">
              {error?.message || 'An error occurred during sign in'}
            </div>
          )}

          {isSuccess && (
            <div className="bg-success/20 text-success rounded-lg p-4 text-sm">
              Successfully signed in!
            </div>
          )}

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={cn(
                'btn btn-primary w-full',
                isLoading && 'loading'
              )}
            >
              {isLoading ? 'Signing in...' : 'Sign in'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignIn; 